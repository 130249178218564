<template>
  <CRow>
    <CCol col="12" xl="12">

      <GoogleMapLoader
      :mapConfig="mapConfig"
      apiKey="AIzaSyA464qcmgzE7HTRTiN_m-09c9xeOb8LmsE"
      >
      <template slot-scope="{ google, map }">
          <GoogleMapMarker
          v-for="marker in markers"
          :key="marker.id"
          :marker="marker"
          :googleMapMarkers="googleMapMarkers"
          :google="google"
          :map="map"
          :pulse="true"
          />
          <GoogleMapLine
          v-for="line in lines"
          :key="line.id"
          :path.sync="line.path"
          :googleMapLines="googleMapLines"
          :google="google"
          :map="map"
          />
      </template>
      </GoogleMapLoader>    

    </CCol>
  </CRow>

</template>

<script>
import GoogleMapLoader from '@/views/maps/GoogleMapLoader'
import GoogleMapMarker from '@/views/maps/GoogleMapMarker'
import GoogleMapLine from '@/views/maps/GoogleMapLine'

import {
  mapSettings,
  LINE_PATH_CONFIG
} from '@/constants/mapSettings'

import { mapActions } from 'vuex'

export default {
  name: 'GoogleMap',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapLine
  },
  data () {
    console.log('GoogleMap::data this.$store.state.auth: ', this.$store.state.auth)
    return {
      linePathConfig: LINE_PATH_CONFIG,
      mapSettings,
      googleMapMarkers: [],
      googleMapLines: [],          
      markers: [
        { id: "a", position: { lat: 37.43887704803831, lng: 127.12116432679464 } },
        { id: "b", position: { lat: 37.51733846572042, lng: 127.04128062679679 } },
        { id: "c", position: { lat: 37.59899788956723, lng: 127.07911368261911 } }
      ],
      lines: [
        { id: "1", path: [{ lat: 37.43887704803831, lng: 127.12116432679464 }, { lat: 37.51733846572042, lng: 127.04128062679679 }] },
        { id: "2", path: [{ lat: 37.51733846572042, lng: 127.04128062679679 }, { lat: 37.59899788956723, lng: 127.07911368261911 }] }
      ]      
    }
  },

  computed: {
    mapConfig () {
      return {
        ...mapSettings,
        zoom: 11,
        center: this.mapCenter
      }
    },

    mapCenter () {
      return this.markers[1].position
    }
  },
  
  methods: {
    // ...mapActions([
    //   'setGoogleMap'
    // ]),

    selectMarker(marker, googleMarker) {
      const {
        reportTime,
        position: {
          lat,
          lng
        },
        vesselName,
        vesselId
      } = marker
      // const InfoWindow = Vue.extend(GoogleMapInfoWindow)
      // const infoWindowInstance = new InfoWindow({
      //   propsData: {
      //     vesselName,
      //     reportTime,
      //     lat: decimalToDMS(lat),
      //     lng: decimalToDMS(lng, false),
      //     vesselId
      //   }
      // })
      // infoWindowInstance.$mount()
      // // eslint-disable-next-line no-undef
      // const infoWindow = new google.maps.InfoWindow({
      //   content: infoWindowInstance.$el,
      // })
      // if (this.infoWindow) this.infoWindow.close()
      // this.infoWindow = infoWindow
      // infoWindow.open(this.map, googleMarker)
    }
  }
}
</script>

<style scoped lang='scss'>
  .google-map {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    border-top: solid 2px #FFF;
    border-bottom: solid 2px #FFF;
    &__map {
      height: 460px;
      width: 100%;
    }
    &__placeholder {
      height: 460px;
      width: 100%;
    }
  }
</style>